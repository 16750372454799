<template>

    <b-modal no-close-on-backdrop no-close-on-esc id="modal_saiba_mais_mapa" size="md"  :hide-header="true" hide-footer>
        <div class="close-button float-right"><button size="lg" @click="close()" title="Fechar janela">
              <i class="text-danger fa fa-times fa-lg"></i>
            </button>
        </div>
        <div class="card h-100 py-1">
            <h4><b>Saiba Mais</b></h4>
            <div class="session-gap"></div>
            <div class="session-gap"></div>
            <div class="textCitar">Os mapas de distribuição são produzidos a partir de registros utilizados no momento da avaliação, porém, só são disponibilizados aqueles sem carência (conforme Política de Dados estabelecida pela <a href="saibaMais.pdf" target="_blank">IN ICMBio Nº 05/2017</a>). Dessa forma, eventualmente, novos registros podem surgir na medida em que algumas carências são vencidas; ou pontos são incluídos ou retirados das bases de dados. Trata-se, portanto, de um mapa mais dinâmico.</div>

        </div>
    </b-modal>
</template>

<script>
import barramento from '@/barramento'

export default {
    created() {
        barramento.$on('modal_saiba_mais_mapa', () => {

            this.$bvModal.show('modal_saiba_mais_mapa')
        })
    },
    methods: {

        close(){
             this.$bvModal.hide('modal_saiba_mais_mapa')
        }

    }
}
</script>

<style lang="scss">

    #modal_saiba_mais_mapa {
        .modal-dialog .modal-content {
            border-radius: 15px !important;
            padding: 30px !important;
            background-color: #E2F5A1;

            box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
            -webkit-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
            -moz-box-shadow: 23px 20px 31px -10px rgba(0,0,0,0.72);
        }
        .card {
            background-color: #E2F5A1;
            margin-top: 10px;
        }
        .close-button button {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 0;
            right: 0;
        }
        .textCitar {
            font-size: 12px;
            color: #646464;
        }
        .session-gap {
            height: 15px;
        }
        .card b {
            width: 50%;
        }

    }
</style>